<template>
  <div class="edit-coupon">
    <div class="edy-year-add">
      <content-not-view v-if="!subPermission.update" />
      <loading v-else-if="isLoading" />
      <b-card v-else>
        <validation-observer ref="form">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group :label="$t('coupons.name')" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('coupons.name')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="formData.name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("coupons.name")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$t('coupons.code')" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('coupons.code')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="formData.code"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("coupons.code")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$t('coupons.value')" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('coupons.value')"
                  >
                    <b-input-group :append="$t('g.currency')">
                      <b-form-input
                        v-model="formData.value"
                        :state="errors.length > 0 ? false : null"
                        :formatter="$helpers.intOnlyFormater"
                      />
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("coupons.value")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('coupons.minimumCheckout')"
                  label-for="minimumCheckout"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('coupons.minimumCheckout')"
                  >
                    <b-input-group :append="$t('g.currency')">
                      <b-form-input
                        v-model="formData.max_value"
                        :state="errors.length > 0 ? false : null"
                        :formatter="$helpers.intOnlyFormater"
                      />
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("coupons.minimumCheckout")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('coupons.usage_limit')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('coupons.usage_limit')"
                  >
                    <b-form-input
                      v-model="formData.times_left"
                      :state="errors.length > 0 ? false : null"
                      :formatter="$helpers.intOnlyFormater"
                    ></b-form-input>
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("coupons.usage_limit")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$t('coupons.startDate')" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('coupons.startDate')"
                  >
                    <b-form-datepicker
                      v-model="formData.start_date"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("coupons.startDate")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('coupons.expire_date')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('coupons.expire_date')"
                  >
                    <b-form-datepicker
                      v-model="formData.expiry_date"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(
                            errors[0],
                            $t("coupons.expire_date")
                          )
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  {{ $t("g.save") }}
                </b-button>
                <b-button
                  variant="outline-primary"
                  @click="redirect"
                  class="mx-1"
                >
                  {{ $t("g.close") }}
                </b-button>
              </b-col>
            </b-row>
            <overlay-component :is-loading="isBusy" />
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
  BInputGroup,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import loading from "@/components/loading/loading.vue";
import overlayComponent from "@/components/shared/OverlayComponent/index.vue";
import { required } from "@core/utils/validations/validations";

export default {
  name: "EditCoupon",
  components: {
    BFormDatepicker,
    BButton,
    BCard,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    BFormGroup,
    BRow,
    BFormInput,
    BFormFile,
    BInputGroup,
    flatPickr,
    loading,
    overlayComponent,
  },
  data() {
    return {
      formData: {
        code: null,
        value: null,
        times_left: null,
        expiry_date: null,
        start_date: null,
        name: null,
        max_value: null,
      },

      isLoading: false,
      archive: {},
      isBusy: false,
    };
  },
  created() {
    if (this.$route.params.id) this.getData(this.$route.params.id);
  },
  watch: {
    "$route.params.id"(newValue, oldValue) {
      this.getData(newValue);
    },
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "coupons");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    async getData(_id) {
      this.isLoading = true;
      try {
        const response = await this.$http.get(`admin/coupons/${_id}`);
        if (response.status === 200 || response.status === 201) {
          for (const item in this.formData) {
            this.formData[item] = response.data.data[item];
            this.archive[item] = response.data.data[item];
          }
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async validationForm() {
      this.isBusy = true;
      try {
        const validation = await this.$refs.form.validate();
        if (validation) {
          this.submit();
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    async submit() {
      try {
        const { id } = this.$route.params;
        const formData = new FormData();
        for (const item in this.formData) {
          if (this.formData[item] !== this.archive[item]) {
            formData.append(item, this.formData[item]);
          }
        }
        const response = await this.$http.post(
          `admin/coupons/${id}?_method=put`,
          formData
        );
        if (response.status === 200 || response.status === 201) {
          this.redirect();
          this.$helpers.makeToast(
            "success",
            this.$t("g.alert.send.successTitle"),
            this.$t("g.alert.send.successBody")
          );
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    redirect() {
      for (const item in this.formData) {
        this.formData[item] = null;
      }
      this.$router.push({ name: "couponsList" });
    },
  },
};
</script>
<style></style>
